import { Button, Form, Input, Modal, Typography } from "antd";
import React, { FC } from "react";
import { useSubmitSkipVerification } from "../hooks/useSubmitSkipVerification";
import { Store } from "antd/lib/form/interface";
import { SubmitSkipVerificationRequestType } from "../../loanRequestSlice/loanRequest.types";
import { VerificationDetails } from "../index";

const maxCount = 100;
const minCount = 5;
const FormItem = Form.Item;

interface SubmitSkipVerificationRequestProps {
  data?: VerificationDetails;
  visible?: boolean;
  onCancel?(): void;
  loanRequestReference?: string;
}

const SubmitSkipVerificationRequest: FC<SubmitSkipVerificationRequestProps> = ({
  data,
  onCancel,
  visible,
  loanRequestReference,
}) => {
  const [form] = Form.useForm();

  const { submitSkipVerificationLoading, submitSkipVerification } =
    useSubmitSkipVerification();

  const onFinish = (values: Store) => {
    if (values?.reason) {
      const payload: SubmitSkipVerificationRequestType = {
        comment: values.reason,
        verificationReference: data?.reference as string,
        loanRequestReference: loanRequestReference as string,
      };
      submitSkipVerification(payload, () => {
        form.resetFields();
        if (onCancel) {
          onCancel();
        }
      });
    }
  };

  return (
    <>
      <Modal
        destroyOnClose={true}
        centered
        className="modal-lg my-6"
        width={900}
        open={visible}
        onCancel={onCancel}
        title={
          <Typography.Title level={4}>
            {"Skip Field Verification"}
          </Typography.Title>
        }
        footer={null}
      >
        <Form
          layout="vertical"
          form={form}
          name="resend-verification"
          onFinish={onFinish}
        >
          <FormItem
            label="Reason"
            name="reason"
            rules={[
              {
                required: true,
                message: "Reason cannot be empty",
              },
              { min: minCount, message: `Minimum of ${minCount} characters` },
              { max: maxCount, message: `Maximum of ${maxCount} characters` },
            ]}
          >
            <Input.TextArea
              size="large"
              placeholder=""
              className="w-full"
              rows={4}
              showCount
              maxLength={maxCount}
            />
          </FormItem>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldsValue }) => {
              const { reason } = getFieldsValue();
              const formIsComplete = !!reason;
              return (
                <div className="flex justify-end mt-[30px]">
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={
                      !formIsComplete ||
                      form
                        .getFieldsError()
                        .filter(({ errors }) => errors.length).length > 0
                    }
                    loading={submitSkipVerificationLoading}
                  >
                    Submit
                  </Button>
                </div>
              );
            }}
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default SubmitSkipVerificationRequest;
