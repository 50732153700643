import { Descriptions, Divider, Spin, Tag, Typography } from "antd";
import React, { FC, useEffect, useState } from "react";
import { useQueryLoanRequestDetails } from "../../../LoanRequestsManagement/LoanRequestdetails/hooks/useQueryLoanRequestDetails";
import { convertStatusTextToColor } from "../../../../../utils/workflow";
import { loanRequestStatusConverter } from "../../../LoanRequestsManagement/ViewLoanRequests";
import { parseAmountToCurrencyValue } from "../../../../../utils/dataParser";
import { data } from "autoprefixer";
import moment from "moment";

interface ActionParameters {
  loanRequestReference: string;
  verificationReference?: string;
  comment: string;
}

interface Props {
  src: { skipFieldVerificationRequestDTO: ActionParameters };
}

interface EntityType {
  reference: string;
  type: "GUARANTOR" | "BUSINESS" | "BUSINESS_OWNER";
  verificationDate: string; // You can use a Date type if you plan to work with Date objects.
  acceptanceStatus: string;
  status: string;
  createdDate: string; // Similarly, you may use a Date type here if needed.
  name: string;
}

const SkipFieldVerificationViewer: FC<Props> = ({ src }) => {
  const [entityData, setEntityData] = useState<EntityType | undefined>();

  const { loanRequestDetails, loanRequestDetailsLoading } =
    useQueryLoanRequestDetails({
      payload: {
        id: src?.skipFieldVerificationRequestDTO?.loanRequestReference,
      },
    });

  useEffect(() => {
    if (loanRequestDetails) {
      const response =
        loanRequestDetails?.fieldVerificationRequestDetails?.find(
          (entity: { reference: string | undefined }) =>
            entity.reference ===
            src?.skipFieldVerificationRequestDTO?.verificationReference,
        );
      setEntityData(response);
    }
  }, [loanRequestDetails]);

  return (
    <div className="p-[25px]">
      {loanRequestDetailsLoading ? (
        <section className="flex items-center justify-center">
          <Spin tip="Loading Offer Details..." />
        </section>
      ) : (
        <>
          <Descriptions layout="vertical">
            <Descriptions.Item
              label={<Typography.Text strong>Request Status</Typography.Text>}
            >
              <Tag
                color={convertStatusTextToColor(
                  loanRequestDetails?.requestStatus,
                )}
                className="whitespace-break-spaces"
                style={{ whiteSpace: "break-spaces" }}
              >
                {loanRequestStatusConverter(loanRequestDetails?.requestStatus)}
              </Tag>
            </Descriptions.Item>
            <Descriptions.Item
              label={<Typography.Text strong>Business Name</Typography.Text>}
            >
              {loanRequestDetails?.businessName
                ? loanRequestDetails?.businessName
                : "-----"}
            </Descriptions.Item>
            <Descriptions.Item
              label={
                <Typography.Text strong>Business Owner Name</Typography.Text>
              }
            >
              {loanRequestDetails?.businessOwnerName
                ? loanRequestDetails?.businessOwnerName
                : "-----"}
            </Descriptions.Item>

            <Descriptions.Item
              label={<Typography.Text strong>Loan Amount</Typography.Text>}
            >
              {loanRequestDetails?.loanAmount
                ? parseAmountToCurrencyValue(
                    loanRequestDetails?.loanAmount,
                    "₦",
                  )
                : "-----"}
            </Descriptions.Item>
            <Descriptions.Item
                label={<Typography.Text strong>Created Date</Typography.Text>}
            >
              {moment(loanRequestDetails?.createdDate).format("DD/MM/YYYY")}
            </Descriptions.Item>
          </Descriptions>
          <Divider />
          <Descriptions layout="vertical" title={entityData?.type}>
            <Descriptions.Item
              label={<Typography.Text strong>Type</Typography.Text>}
            >
              {entityData?.type}
            </Descriptions.Item>
            <Descriptions.Item
              label={<Typography.Text strong>Name</Typography.Text>}
            >
              {entityData?.name}
            </Descriptions.Item>
            <Descriptions.Item
              label={
                <Typography.Text strong>Acceptance Status</Typography.Text>
              }
            >
              <Tag
                color={convertStatusTextToColor(
                  entityData?.acceptanceStatus as string,
                )}
                className="whitespace-break-spaces"
                style={{ whiteSpace: "break-spaces" }}
              >
                {loanRequestStatusConverter(
                  entityData?.acceptanceStatus as string,
                )}
              </Tag>
            </Descriptions.Item>
            <Descriptions.Item
              label={<Typography.Text strong>Status</Typography.Text>}
            >
              <Tag
                color={convertStatusTextToColor(entityData?.status as string)}
                className="whitespace-break-spaces"
                style={{ whiteSpace: "break-spaces" }}
              >
                {loanRequestStatusConverter(entityData?.status as string)}
              </Tag>
            </Descriptions.Item>
          </Descriptions>
        </>
      )}
    </div>
  );
};
export default SkipFieldVerificationViewer;
