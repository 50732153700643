const danger = '#f50';         // Red: Indicates a critical issue or rejection.
const success = '#87d068';     // Green: Indicates success or approval.
const warning = '#f39834';     // Orange: Indicates a warning or pending action.
const info = '#1890ff';        // Blue: Indicates informational status.
const neutral = '#888888';     // Gray: Indicates a neutral or inactive status.
const primary = '#2db7f5';     // Light Blue: Indicates primary or important status.
const secondary = '#7265e6';   // Purple: Indicates secondary or in-progress status.

export const convertStatusTextToColor = (text: string) => {
    let selectedColor = warning;
    let status = text ? text.toLowerCase() : text;
    switch (status) {
        case 'approved':
        case 'successful':
        case 'accepted':
        case 'passed_eligibility_check':
        case 'active':
            selectedColor = success;
            break;
        case 'pending_loan_approval':
        case 'awaiting_eligibility_check':
        case 'awaiting_loan_offer':
        case 'inactive':
            selectedColor = warning;
            break;
        case 'rejected':
        case 'failed':
        case 'failed_eligibility_check':
        case 'terminated':
            selectedColor = danger;
            break;
        case 'eligibility_expired':
        case 'pending':
        case 'pending_kyc_confirmation':
        case 'offer_expired':
        case 'expired':
            selectedColor = neutral;
            break;
        case 'draft':
        case 'filling_loan_request':
            selectedColor = primary;
            break;
        case 'no_loan_offer':
        case 'trigger_loan_request_approval':
            selectedColor = info;
            break;
        case 'perusing_loan_offer':
            selectedColor = secondary;
            break;
        default:
            selectedColor = warning;
    }

    return selectedColor;
};
