import { Navigate, RouteObject } from "react-router-dom";
import ViewBusinessRelationshipManagerDetails from "../../modules/Dashboard/BRMManagement/Brmdetails";
import BusinessRelationshipManagers from "../../modules/Dashboard/BRMManagement/BusinessRelationshipManagers";
import DashboardHome from "../../modules/Dashboard/Dashboard";
import ViewFcosManagers from "../../modules/Dashboard/FCOManagement/FCOManagers";
import ViewFCODetails from "../../modules/Dashboard/FCOManagement/FCOdetails";
import LgaActivationPage from "../../modules/Dashboard/LgaActivation/LgaActivationPage";
import LoanContractDetails from "../../modules/Dashboard/LoanContractsManagement/LoanContractDetails";
import ViewLoanContracts from "../../modules/Dashboard/LoanContractsManagement/ViewLoanContracts";
import CreateLoanProgram from "../../modules/Dashboard/LoanPrograms/CreateLoanProgram";
import EditLoanProgram from "../../modules/Dashboard/LoanPrograms/EditLoanProgram";
import LoanProgramDetails from "../../modules/Dashboard/LoanPrograms/LoanProgramDetails";
import LoanPrograms from "../../modules/Dashboard/LoanPrograms/LoanPrograms";
import ViewAllLoanRequests from "../../modules/Dashboard/LoanRequestsManagement/AllLoanRequests";
import ViewAllLoanRequestDetails from "../../modules/Dashboard/LoanRequestsManagement/AllLoanRequests/loanRequestDetails";
import ViewLoanRequestDetails from "../../modules/Dashboard/LoanRequestsManagement/LoanRequestdetails";
import ViewLoanRequestsManagers from "../../modules/Dashboard/LoanRequestsManagement/LoanRequestsManager";
import Logout from "../../modules/Dashboard/Logout";
import CreateFcoPortfolioLevel from "../../modules/Dashboard/PortfolioManagement/CreateFcoPortfolioLevel";
import ViewPortofolioManagers from "../../modules/Dashboard/PortfolioManagement/PortFolioManagers";
import PortfolioManagementSettings from "../../modules/Dashboard/PortfolioManagement/Settings";
import PortfolioManagerDetails from "../../modules/Dashboard/PortfolioManagement/portfolioManagerDetails";
import ProvisioningTemplate from "../../modules/Dashboard/ProvisioningTemplate/ProvisionTemplate";
import StateCoordinatorsDetails from "../../modules/Dashboard/StateCoordinatorManagement/StateCoordinatorDetails";
import ViewStateCoordinatorsManagers from "../../modules/Dashboard/StateCoordinatorManagement/StateCoordinatorManagers";
import ViewUserDetails from "../../modules/Dashboard/Users/UserDetails";
import ViewUsersManagers from "../../modules/Dashboard/Users/UsersManager";
import RegionalManagersDetailsPage from "../../modules/Dashboard/WCLRMManagement/RegionalManagerDetailsPage";
import RegionalManagersPage from "../../modules/Dashboard/WCLRMManagement/RegionalManagersPage";
import PortfolioManagementCompletedWorkflow from "../../modules/Dashboard/Workflow/CompletedWorkflows/portfolioManagement";
import BackofficeWCLCompletedWorkflow from "../../modules/Dashboard/Workflow/CompletedWorkflows/wclBackoffice";
import WCLCompletedWorkflow from "../../modules/Dashboard/Workflow/CompletedWorkflows/workingCapitalLoans";
import PortfolioManagementPendingApprovalsWorkflow from "../../modules/Dashboard/Workflow/PendingApprovals/portfolioManagement";
import WCLBackofficePendingApprovalsWorkflow from "../../modules/Dashboard/Workflow/PendingApprovals/wclBackoffice";
import WCLPendingApprovalsWorkflow from "../../modules/Dashboard/Workflow/PendingApprovals/workingCapitalLoans";
import { DashboardRoutePaths } from "./routePaths";
import EnableLoan from "../../modules/Dashboard/LoanRequestsManagement/AllLoanRequests/components/EnableLoan";

export const DashBoardRoutes: RouteObject[] = [
  {
    path: DashboardRoutePaths.home.relativePath,
    element: <DashboardHome />,
  },
  {
    path: DashboardRoutePaths.brms.relativePath,
    element: <BusinessRelationshipManagers />,
  },
  {
    path: DashboardRoutePaths.brmdetails.relativePath,
    element: <ViewBusinessRelationshipManagerDetails />,
  },
  {
    path: DashboardRoutePaths.wclWorkflowPendingApprovals.relativePath,
    element: <WCLPendingApprovalsWorkflow />,
  },
  {
    path: DashboardRoutePaths.wclBackofficeWorkflowPendingApprovals
      .relativePath,
    element: <WCLBackofficePendingApprovalsWorkflow />,
  },
  {
    path: DashboardRoutePaths.wclCompletedWorkflow.relativePath,
    element: <WCLCompletedWorkflow />,
  },
  {
    path: DashboardRoutePaths.portfolioManagementCompletedWorkflow.relativePath,
    element: <PortfolioManagementCompletedWorkflow />,
  },
  {
    path: DashboardRoutePaths.portfolioManagementWorkflowPendingApprovals
      .relativePath,
    element: <PortfolioManagementPendingApprovalsWorkflow />,
  },
  {
    path: DashboardRoutePaths.createPortfolioLevel.relativePath,
    element: <CreateFcoPortfolioLevel />,
  },
  {
    path: DashboardRoutePaths.viewPortfolioManagements.relativePath,
    element: <ViewPortofolioManagers />,
  },
  {
    path: DashboardRoutePaths.viewProvisioning.relativePath,
    element: <ProvisioningTemplate />,
  },
  {
    path: DashboardRoutePaths.loanPrograms.relativePath,
    element: <LoanPrograms />,
  },
  {
    path: DashboardRoutePaths.viewLoanRequestDetails.relativePath,
    element: <ViewLoanRequestDetails />,
  },
  {
    path: DashboardRoutePaths.viewLoanRequests.relativePath,
    element: <ViewLoanRequestsManagers />,
  },
  {
    path: DashboardRoutePaths.viewAllLoanRequests.relativePath,
    element: <ViewAllLoanRequests />,
  },
  {
    path: DashboardRoutePaths.viewAllLoanRequestDetails.relativePath,
    element: <ViewAllLoanRequestDetails />,
  },
  {
    path: DashboardRoutePaths.enableLoan.relativePath,
    element: <EnableLoan />,
  },
  {
    path: DashboardRoutePaths.wclLgaActivation.relativePath,
    element: <LgaActivationPage />,
  },
  {
    path: DashboardRoutePaths.portfolioManagementSettings.relativePath,
    element: <PortfolioManagementSettings />,
  },
  {
    path: DashboardRoutePaths.viewFcosManagers.relativePath,
    element: <ViewFcosManagers />,
  },
  {
    path: DashboardRoutePaths.viewFcosManagerDetails.relativePath,
    element: <ViewFCODetails />,
  },
  {
    path: DashboardRoutePaths.viewStateCoordinators.relativePath,
    element: <ViewStateCoordinatorsManagers />,
  },
  {
    path: DashboardRoutePaths.stateCoordinatorDetails.relativePath,
    element: <StateCoordinatorsDetails />,
  },
  {
    path: DashboardRoutePaths.portfolioManagerDetails.relativePath,
    element: <PortfolioManagerDetails />,
  },
  {
    path: DashboardRoutePaths.loanProgramDetails.relativePath,
    element: <LoanProgramDetails />,
  },
  {
    path: DashboardRoutePaths.createLoanProgram.relativePath,
    element: <CreateLoanProgram />,
  },
  {
    path: DashboardRoutePaths.editLoanProgram.relativePath,
    element: <EditLoanProgram />,
  },
  {
    path: DashboardRoutePaths.WCLRegionalManagers.relativePath,
    element: <RegionalManagersPage />,
  },
  {
    path: DashboardRoutePaths.WCLRegionalManagersDetails.relativePath,
    element: <RegionalManagersDetailsPage />,
  },
  {
    path: DashboardRoutePaths.viewAllUsers.relativePath,
    element: <ViewUsersManagers />,
  },
  {
    path: DashboardRoutePaths.viewUserDetails.relativePath,
    element: <ViewUserDetails />,
  },
  {
    path: DashboardRoutePaths.loanContracts.relativePath,
    element: <ViewLoanContracts />,
  },
  {
    path: DashboardRoutePaths.loanContractsDetails.relativePath,
    element: <LoanContractDetails />,
  },
  {
    path: DashboardRoutePaths.viewBackofficeCompletedWorkflows.relativePath,
    element: <BackofficeWCLCompletedWorkflow />,
  },
  {
    path: DashboardRoutePaths.logout.relativePath,
    element: <Logout />,
  },
  {
    path: "*",
    element: <Navigate to={"/"} />,
  },
];
