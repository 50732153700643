import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Modal,
  Spin,
  Table,
  Tag,
  Typography,
  Form,
  DatePicker,
  Input,
} from "antd";
import { LockOutlined } from "@ant-design/icons";
import ServerErrorAlert from "../../../../components/ServerErrorAlert";
import useQueryInterestWaivePeriods from "../hooks/useQueryInterestWaivePeriods";
import moment from "moment/moment";
import { convertStatusTextToColor } from "../../../../utils/workflow";
import { convertStatusToHumanReadable } from "../../../../utils/dataParser";
import { showMessage, showServerError } from "../../../../utils/notifications";
import useQueryPendingApprovalRequest from "../hooks/useQueryPendingApprovalRequest";
import useCreateInterestWaiver from "../hooks/useCreateInterestWaiver";
import useQueryPendingTerminationRequest from "../hooks/useQueryPendingTerminationRequest";

const { Title } = Typography;
const { TextArea } = Input;

const InterestWaivePeriods = ({ id }: { id: string }) => {
  const {
    isLoadingWaivePeriods,
    waivePeriodsPermissionError,
    interestWavePeriods,
  } = useQueryInterestWaivePeriods({ ref: id });

  const {
    isLoadingPendingApprovalRequest,
    hasPendingRequest,
    checkPendingApprovalRequest,
  } = useQueryPendingApprovalRequest({ ref: id });

  const {
   isLoadingPendingTerminationRequest,
      hasPendingTerminationRequest
  } = useQueryPendingTerminationRequest({ ref: id });

  const { isLoading: isCreatingInterestWaiver, createInterestWaiver } = useCreateInterestWaiver();

  const [shouldShowWaiveInterestButton, setShouldShowWaiveInterestButton] =
    useState(false);
  const [showTerminateWaiveButton, setShowTerminateWaiveButton] = useState(false)
  const [
    showWaiveInterestConfirmationPopUp,
    setShowWaiveInterestConfirmationPopUp,
  ] = useState(false);
  const [form] = Form.useForm();

 const tableColumns = [
  {
    title: "Start Date",
    dataIndex: "startDate",
    key: "startDate",
    render: (value: string) => {
      if (value) {
        return <div>{moment(value).format("YYYY-MM-DD")}</div>;
      } else {
        return <div>{"-----"}</div>;
      }
    },
  },
  {
    title: "End Date",
    dataIndex: "endDate",
    key: "endDate",
    render: (value: string) => {
      if (value) {
        return <div>{moment(value).format("YYYY-MM-DD")}</div>;
      } else {
        return <div>{"-----"}</div>;
      }
    },
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (value: string) => {
      return (
        <Tag color={convertStatusTextToColor(value)}>
          {convertStatusToHumanReadable(value)}
        </Tag>
      );
    },
  },
  {
    title: "Action",
    key: "action",
    render: () => {
      if (!isLoadingPendingTerminationRequest && showTerminateWaiveButton) {
        return <Button type="primary">Terminate</Button>;
      }
      return <div>{"-----"}</div>;
    },
  },
];

  useEffect(() => {
    if (!isLoadingWaivePeriods && !isLoadingPendingApprovalRequest) {
      const shouldShowButton =
          !isLoadingPendingApprovalRequest &&
          interestWavePeriods &&  interestWavePeriods?.length > 0 &&
          !interestWavePeriods.find((i) => i.status === "ACTIVE") &&
          !hasPendingRequest;
      setShouldShowWaiveInterestButton(shouldShowButton ?? false);
    }
  }, [
    isLoadingPendingApprovalRequest,
    interestWavePeriods,
    hasPendingRequest,
    isLoadingWaivePeriods,
  ]);

  useEffect(() => {
    if (!isLoadingWaivePeriods && !isLoadingPendingTerminationRequest) {
      const shouldShowTerminationButton =
          !isLoadingPendingApprovalRequest &&
          interestWavePeriods &&  interestWavePeriods?.length > 0 &&
          interestWavePeriods.find((i) => i.status === "ACTIVE") &&
          !hasPendingTerminationRequest;
      setShowTerminateWaiveButton(shouldShowTerminationButton ?? false);
    }
  }, [
    isLoadingPendingTerminationRequest,
    interestWavePeriods,
    hasPendingTerminationRequest,
    isLoadingWaivePeriods,
  ]);

  const handleConfirm = () => {
    form
      .validateFields()
      .then(async () => {
        await createInterestWaiver({
          payload: {
            contractReference: id,
            startDate: form.getFieldValue("startDate").format("YYYY-MM-DD"),
            endDate: form.getFieldValue("endDate").format("YYYY-MM-DD"),
            comment: form.getFieldValue("comments"),
          },
          callback: () => {
            showMessage("SuccessFul", "Request has been sent for approval.");
            checkPendingApprovalRequest();
            form.resetFields();
            setShowWaiveInterestConfirmationPopUp(false);
          },
        });
      })
      .catch((error: any) => {
        showServerError(error);
      });
  };

  return (
    <>
      <Card
        title={
          <div className="flex items-center justify-between">
            <div>Interest Waive Periods</div>
            {!isLoadingPendingApprovalRequest &&
              !isLoadingWaivePeriods &&
              shouldShowWaiveInterestButton && (
                <Button
                  type="primary"
                  onClick={() => setShowWaiveInterestConfirmationPopUp(true)}
                >
                  Waive Interest
                </Button>
              )}
          </div>
        }
        className="mt-4"
      >
        {isLoadingWaivePeriods ? (
          <section className="flex items-center justify-center my-8">
            <Spin tip="Loading Details..." />
          </section>
        ) : waivePeriodsPermissionError ? (
          <section className="flex flex-col items-center justify-center my-8">
            <LockOutlined className="text-5xl mb-5" />
            <ServerErrorAlert message="You do not have permission to view this section." />
          </section>
        ) : (
          <Table
            columns={tableColumns}
            dataSource={interestWavePeriods || []}
            pagination={false}
            rowKey={(record) => record.waivePeriodId}
            loading={isLoadingWaivePeriods}
          />
        )}
      </Card>

      <Modal
        destroyOnClose={true}
        centered
        open={showWaiveInterestConfirmationPopUp}
        title={<Title level={4}>Waive Interest</Title>}
        width={800}
        okText="Confirm"
        confirmLoading={isCreatingInterestWaiver}
        onOk={handleConfirm}
        onCancel={() => {
          form.resetFields();
          setShowWaiveInterestConfirmationPopUp(false);
        }}
      >
        <Form
          form={form}
          layout="vertical"
          name="waive_interest_form"
          initialValues={{ startDate: moment() }}
          size="large"
        >
          <Form.Item
            name="startDate"
            label="Start Date"
            rules={[
              { required: true, message: "Please select a start date" },
              () => ({
                validator(_, value) {
                  if (!value || value.isAfter(moment())) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("Start date must be greater than current date"),
                  );
                },
              }),
            ]}
            style={{ width: "100%" }}
          >
            <DatePicker style={{ width: "100%" }} />
          </Form.Item>

          <Form.Item
            name="endDate"
            label="End Date"
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || value.isAfter(getFieldValue("startDate"))) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("End date must be greater than start date"),
                  );
                },
              }),
            ]}
            style={{ width: "100%" }}
          >
            <DatePicker style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            name="comments"
            label="Comments"
            rules={[{ required: true, message: "Please add your comments" }]}
          >
            <TextArea rows={4} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default InterestWaivePeriods;
