import { Alert, Button, Descriptions, Row, Spin, Tag, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { showError } from "../../../../../../utils/notifications";
import {
  ELoanContractProduct,
  ELoanRequestProduct,
} from "../../../../LoanContractsManagement/utils/types";
import { buildUpdatedLoanOfferData } from "../../../../Workflow/Viewers/LoanUpdateViewer";
import { labels } from "../../labels";
import PermissionDenied from "../LoanOfferErrorDetails";
import { validateLoanRequestFilter } from "../LoanRequestTabs";
import ConfirmLoanOfferChangesModal from "../../../../../../components/Modals/ConfirmLoanOfferChangesModal";
import OVDConversionConfirmationModal from "../../../../../../components/Modals/OVDConversionConfirmationModal";
import CustomDescriptionItem from "./LoanOfferItem";
import {
  convertDateToHumanReadableFrequency,
  convertStatusToHumanReadable,
  parseAmountToCurrencyValue,
} from "../../../../../../utils/dataParser";
import { convertStatusTextToColor } from "../../../../../../utils/workflow";
import { LoanOfferStatus } from "../../utils/types";
import { useEditLoanOfferDetail } from "../../hooks/useEditLoanOfferDetail";
import { useConvertWCLToOVD } from "../../hooks/useConvertWCLToOVD";
import { useLoanConversionEligibility } from "../../hooks/useLoanConversionEligibility";
import { useLocation } from "react-router-dom";
import { updateRules } from "../../updateRules";
import omit from "lodash/omit";

const { Text } = Typography;

const LoanOfferDetails = ({
  loanDetails,
  loading,
  error,
}: {
  loanDetails: any;
  loading: boolean;
  error?: any;
}) => {
  const location = useLocation();
  const { loanRequestStatusTab } = location.state || {};

  const [formState, setFormState] = useState({
    editMode: false,
    editedData: { ...loanDetails },
    showPreviewChangesModal: false,
    showOVDConversionConfirmationPopUp: false,
    wclToOvdConversionFormIsActive: false,
  });
  const { editLoanOfferDetail, loading: editLoading } =
    useEditLoanOfferDetail();
  const { convertWCLToOVD, loading: isConvertingToOVD } = useConvertWCLToOVD();
  const {
    getLoanConversionEligibility,
    loading: isLoadingEligibility,
    conversionEligibility,
  } = useLoanConversionEligibility();

  const updateStateField = (field: string, value: any) => {
    setFormState((prevState) => ({
      ...prevState,
      editedData: {
        ...prevState.editedData,
        [field]: value,
        edited: true,
      },
    }));
  };

  const handleEdit = () => {
    setFormState((prevState) => ({
      ...prevState,
      editMode: true,
    }));
  };

  const handleDeepCompareOfferDetails = (editedData: any, loanDetails: any) => {
    for (const key in editedData) {
      // Skip the 'edited' flag
      if (key === "edited") return false;
      if (
        formState?.wclToOvdConversionFormIsActive &&
        editedData?.maxDefaultCountToTermination &&
        editedData?.regularizationPeriod
      ) {
        return true;
      } else {
        if (editedData[key] != loanDetails[key]) {
          return true; // Changes detected
        }
      }
    }
    return false; // No changes detected
  };

  const handleSaveChanges = () => {
    const { editedData } = formState;

    const errorValidation = validateLoanRequestFilter(
      removeOVDFieldsIfNeeded(editedData),
      removeOVDFieldsIfNeeded(updateRules),
      removeOVDFieldsIfNeeded(labels),
    );

    if (errorValidation) {
      return showError(errorValidation);
    }

    if (
      !handleDeepCompareOfferDetails(
        removeOVDFieldsIfNeeded(editedData),
        loanDetails,
      )
    ) {
      return showError("No changes made");
    }

    setFormState((prevState) => ({
      ...prevState,
      showPreviewChangesModal: true,
    }));
  };

  const removeOVDFieldsIfNeeded = (data: { [x: string]: any }) => {
    if (
      formState.wclToOvdConversionFormIsActive ||
      formState.editedData?.loanProduct === ELoanContractProduct.OVERDRAFT
    )
      return data;
    return omit(data, ["regularizationPeriod", "maxDefaultCountToTermination"]);
  };

  const handleUpdateLoanData = async () => {
    setFormState((prevState) => ({
      ...prevState,
      showPreviewChangesModal: false,
      editMode: false,
    }));
    await editLoanOfferDetail(
      loanDetails?.loanProduct,
      loanDetails?.loanProduct === ELoanRequestProduct.WCL
        ? loanDetails?.reference
        : loanDetails?.offerReference,
      formState.editedData,
    );
  };

  const handleConvertLoanProduct = async () => {
    if (!loanDetails) return;

    await convertWCLToOVD(
      {
        amount: Number(formState?.editedData?.amount ?? 0),
        dailyInterestRateInPercentage:
          formState?.editedData?.dailyInterestRateInPercentage,
        tenure: formState?.editedData?.tenure,
        loanTenureUnit: formState?.editedData?.loanTenureUnit,
        maxDefaultCountToTermination:
          formState.editedData?.maxDefaultCountToTermination,
        regularizationPeriod: formState.editedData?.regularizationPeriod,
        offerReference: loanDetails?.reference,
        managementFeeDiscountInPercentage:
          formState?.editedData?.managementFeeDiscountInPercentage,
      },
      () => {
        // Success callback: Reset the form state
        setFormState((prevState) => ({
          ...prevState,
          showPreviewChangesModal: false,
          showOVDConversionConfirmationPopUp: false,
          showOVDConversionFormPopUp: false,
          editMode: false,
        }));
        getLoanConversionEligibility(loanDetails?.reference);
      },
    );
  };

  useEffect(() => {
    if (!formState.editMode) {
      setFormState((prevState) => ({
        ...prevState,
        editedData: {
          ...loanDetails,
          // Ensure required fields are included with default or initial values
          maxDefaultCountToTermination:
            loanDetails?.maxDefaultCountToTermination ?? 0,
          regularizationPeriod: loanDetails?.regularizationPeriod ?? 0,
        },
      }));
    }
  }, [loanDetails, formState.editMode]);

  useEffect(() => {
    const shouldRecomputeManagementFee =
      formState.editedData?.managementFeeDiscountInPercentage &&
      formState.editMode;

    if (shouldRecomputeManagementFee) {
      handleRecalculateFees();
    }
  }, [
    formState.editedData?.managementFeeDiscountInPercentage,
    formState.editMode,
  ]);

  const handleBuildOfferDifference = () => {
    let comparedData = buildUpdatedLoanOfferData(
      loanDetails,
      formState.editedData,
      false,
    );
    if (comparedData.loanTenureUnit && !comparedData.tenure) {
      comparedData.tenure = loanDetails.tenure;
    }
    if (comparedData.loanTenureUnit && !comparedData.repaymentFrequency) {
      comparedData.repaymentFrequency = loanDetails.repaymentFrequency;
    }
    if (formState?.wclToOvdConversionFormIsActive) {
      comparedData = {
        ...comparedData,
        maxDefaultCountToTermination:
          formState?.editedData?.maxDefaultCountToTermination,
        regularizationPeriod: formState?.editedData?.regularizationPeriod,
        loanProduct: "OVERDRAFT",
      };
    }
    return comparedData;
  };

  const handleRecalculateFees = () => {
    if (!loanDetails) return;
    const discountedManagementFee =
      loanDetails.originalManagementFee -
      (loanDetails.originalManagementFee *
        formState.editedData.managementFeeDiscountInPercentage) /
        100;
    updateStateField("discountedManagementFee", discountedManagementFee);
  };

  useEffect(() => {
    const shouldGetLoanConversionEligibility =
      loanDetails?.loanProduct === ELoanRequestProduct.WCL &&
      loanDetails?.loanRequestStatus === LoanOfferStatus.PERUSING_LOAN_OFFER;

    if (loanDetails) {
      if (shouldGetLoanConversionEligibility) {
        getLoanConversionEligibility(loanDetails.reference);
      }
    }
  }, [loanDetails, loanRequestStatusTab]);

  const handleCancelEdit = () => {
    setFormState((prevState) => ({
      ...prevState,
      editMode: false,
      editedData: { ...loanDetails },
      wclToOvdConversionFormIsActive: false,
    }));
  };

  if (error)
    return <PermissionDenied errorMessage="Loan Offer details not found" />;

  if (!loanDetails && !loading && !isLoadingEligibility)
    return <div>No loan details available</div>;

  return (
    <>
      {loading || isLoadingEligibility ? (
        <section className="flex items-center justify-center">
          <Spin tip="Loading Offer Details..." />
        </section>
      ) : (
        <>
          {conversionEligibility?.offerConversionEligibility ===
            "OFFER_CONVERSION_PENDING" && (
            <div className="ant-col-md-12 mb-4">
              <Alert
                message="The overdraft conversion request for this offer is awaiting approval."
                type="warning"
                showIcon
              />
            </div>
          )}
          {/* Descriptions block */}
          <Descriptions
            layout="vertical"
            contentStyle={{ marginTop: "-16px", maxWidth: "fit-content" }}
            column={{ xs: 1, md: 2, xl: 3 }}
          >
            <Descriptions.Item label={<Text strong>Loan Product</Text>}>
              {loanDetails?.loanProduct}
            </Descriptions.Item>
            <Descriptions.Item label={<Text strong>Loan Offer Status</Text>}>
              <Tag
                color={convertStatusTextToColor(loanDetails?.loanRequestStatus)}
              >
                {convertStatusToHumanReadable(loanDetails?.loanRequestStatus)}
              </Tag>
            </Descriptions.Item>
            <Descriptions.Item label={<Text strong>Amount</Text>}>
              <CustomDescriptionItem
                value={formState.editedData?.amount}
                editMode={formState.editMode}
                setValue={(value) => updateStateField("amount", value)}
                editProps={{ type: "number" }}
                parseAmount
              />
            </Descriptions.Item>
            {loanDetails?.processingFee &&
              Number(loanDetails?.processingFee) > 0 && (
                <Descriptions.Item label={<Text strong>Processing Fee</Text>}>
                  {parseAmountToCurrencyValue(loanDetails?.processingFee, "₦")}
                </Descriptions.Item>
              )}
            <Descriptions.Item label={<Text strong>Loan Tenure Unit</Text>}>
              <CustomDescriptionItem
                dateTenure
                value={formState.editedData?.loanTenureUnit}
                editMode={formState.editMode}
                dateProps={{ disallowHourly: true }}
                setValue={(value) => updateStateField("loanTenureUnit", value)}
              />
            </Descriptions.Item>
            <Descriptions.Item label={<Text strong>Tenure</Text>}>
              <CustomDescriptionItem
                value={formState.editedData?.tenure}
                editMode={formState.editMode}
                setValue={(value) => updateStateField("tenure", value)}
                renderProps={{
                  suffix:
                    " " +
                    convertDateToHumanReadableFrequency(
                      formState.editedData?.tenure,
                      formState.editedData?.loanTenureUnit,
                    ),
                }}
                editProps={{ type: "number", maxLength: 5 }}
              />
            </Descriptions.Item>

            <Descriptions.Item
              label={<Text strong>Original Management Fee</Text>}
            >
              {parseAmountToCurrencyValue(
                loanDetails?.originalManagementFee,
                "₦",
              )}
            </Descriptions.Item>
            {formState.editedData?.discountedManagementFee ? (
              <Descriptions.Item
                label={<Text strong>Discounted Management Fee</Text>}
              >
                {parseAmountToCurrencyValue(
                  formState.editedData?.discountedManagementFee,
                  "₦",
                )}
              </Descriptions.Item>
            ) : (
              <Descriptions.Item
                label={<Text strong>Discounted Management Fee</Text>}
              >
                {parseAmountToCurrencyValue(
                  formState.editedData?.discountedManagementFee,
                  "₦",
                )}
              </Descriptions.Item>
            )}
            <Descriptions.Item
              label={<Text strong>Management Fee Discount Percentage</Text>}
            >
              <CustomDescriptionItem
                value={formState.editedData?.managementFeeDiscountInPercentage}
                editMode={formState.editMode}
                setValue={(value) =>
                  updateStateField("managementFeeDiscountInPercentage", value)
                }
                editProps={{ type: "number" }}
              />
            </Descriptions.Item>
            <Descriptions.Item
              label={<Text strong>Daily Interest Rate Percentage</Text>}
            >
              <CustomDescriptionItem
                value={formState.editedData?.dailyInterestRateInPercentage}
                editMode={formState.editMode}
                setValue={(value) =>
                  updateStateField("dailyInterestRateInPercentage", value)
                }
                renderProps={{ suffix: "% daily" }}
                editProps={{ type: "number" }}
              />
            </Descriptions.Item>
            <Descriptions.Item
              label={<Text strong>Interest Calculation Frequency</Text>}
            >
              {formState.editedData?.interestCalculationFrequency}
            </Descriptions.Item>
            {loanDetails?.loanProduct === ELoanRequestProduct.WCL && (
              <>
                <Descriptions.Item
                  label={<Text strong>Repayment frequency</Text>}
                >
                  <CustomDescriptionItem
                    value={formState.editedData?.repaymentFrequency}
                    editMode={formState.editMode}
                    setValue={(value) =>
                      updateStateField("repaymentFrequency", value)
                    }
                    editProps={{ type: "number" }}
                    renderProps={{
                      prefix: "Every",
                      suffix:
                        " " +
                        convertDateToHumanReadableFrequency(
                          formState.editedData?.repaymentFrequency,
                          formState.editedData?.loanTenureUnit,
                        ),
                    }}
                  />
                </Descriptions.Item>
              </>
            )}
            {/*Editing WCL to OVD Conversion form*/}
            {formState.wclToOvdConversionFormIsActive && (
              <>
                <Descriptions.Item
                  label={<Text strong>Max Default Count To Termination</Text>}
                >
                  <CustomDescriptionItem
                    value={formState.editedData?.maxDefaultCountToTermination}
                    editMode={formState.editMode}
                    setValue={(value) =>
                      updateStateField("maxDefaultCountToTermination", value)
                    }
                    editProps={{ type: "number", maxLength: 5 }}
                  />
                </Descriptions.Item>

                <Descriptions.Item
                  label={<Text strong>Regularization Period</Text>}
                >
                  <CustomDescriptionItem
                    value={formState.editedData?.regularizationPeriod}
                    editMode={formState.editMode}
                    setValue={(value) =>
                      updateStateField("regularizationPeriod", value)
                    }
                    editProps={{ type: "number", maxLength: 5 }}
                  />
                </Descriptions.Item>
              </>
            )}
            {/*Editing Overdraft Offer Details*/}
            {loanDetails?.loanProduct === ELoanContractProduct.OVERDRAFT && (
              <>
                <Descriptions.Item
                  label={<Text strong>Overdraft Account Number</Text>}
                >
                  {loanDetails?.loanAccountNuban}
                </Descriptions.Item>
                <Descriptions.Item
                  label={<Text strong>Regularization Period</Text>}
                >
                  <CustomDescriptionItem
                    value={formState.editedData?.regularizationPeriod}
                    editMode={formState.editMode}
                    setValue={(value) =>
                      updateStateField("regularizationPeriod", value)
                    }
                    editProps={{ type: "number" }}
                  />
                </Descriptions.Item>
                <Descriptions.Item
                  label={<Text strong>Regularization Period Unit</Text>}
                >
                  {loanDetails?.regularizationPeriodUnit}
                </Descriptions.Item>
                <Descriptions.Item
                  label={<Text strong>Maximum Count to Termination</Text>}
                >
                  <CustomDescriptionItem
                    value={formState.editedData?.maxDefaultCountToTermination}
                    editMode={formState.editMode}
                    setValue={(value) =>
                      updateStateField("maxDefaultCountToTermination", value)
                    }
                    editProps={{ type: "number" }}
                  />
                </Descriptions.Item>
                <Descriptions.Item
                  label={<Text strong>Business Owner Code</Text>}
                >
                  {loanDetails?.businessOwnerCode}
                </Descriptions.Item>
              </>
            )}
          </Descriptions>

          <Row justify="end">
            {loanDetails?.loanRequestStatus ===
              LoanOfferStatus.PERUSING_LOAN_OFFER &&
              loanDetails?.loanProduct ===
                ELoanContractProduct.WORKING_CAPITAL &&
              !formState.editMode && (
                <Button
                  type="primary"
                  className="rounded mb-2 mr-2"
                  onClick={() =>
                    setFormState((prevState) => ({
                      ...prevState,
                      showOVDConversionConfirmationPopUp: true,
                    }))
                  }
                  disabled={
                    isConvertingToOVD ||
                    editLoading ||
                    conversionEligibility?.offerConversionEligibility !==
                      "CONVERTIBLE"
                  }
                >
                  Convert To OVD
                </Button>
              )}

            {!formState.editMode &&
              loanDetails?.loanRequestStatus ===
                LoanOfferStatus.PERUSING_LOAN_OFFER && (
                <Button
                  type="primary"
                  className="rounded mb-2"
                  onClick={handleEdit}
                  disabled={isConvertingToOVD || editLoading}
                >
                  {editLoading ? "Saving details" : "Edit Loan Offer"}
                </Button>
              )}
            {formState.editMode && (
              <>
                <Button
                  type="primary"
                  className="rounded mb-2 mr-4"
                  onClick={handleSaveChanges}
                  disabled={editLoading || !formState.editedData?.edited}
                  loading={editLoading}
                >
                  {editLoading ? "Saving details" : "Save Changes"}
                </Button>
                <Button
                  type="ghost"
                  className="rounded mb-2"
                  onClick={handleCancelEdit}
                  disabled={editLoading}
                >
                  Cancel
                </Button>
              </>
            )}
          </Row>
        </>
      )}
      <ConfirmLoanOfferChangesModal
        visible={formState.showPreviewChangesModal}
        onConfirm={
          formState.wclToOvdConversionFormIsActive
            ? handleConvertLoanProduct
            : handleUpdateLoanData
        }
        onCancel={() =>
          setFormState((prevState) => ({
            ...prevState,
            showPreviewChangesModal: false,
          }))
        }
        loanDetails={loanDetails}
        ovdFormIsActive={formState.wclToOvdConversionFormIsActive}
        comparedData={
          formState?.showPreviewChangesModal ? handleBuildOfferDifference() : {}
        }
        loading={isConvertingToOVD}
        error={error}
      />
      <OVDConversionConfirmationModal
        visible={formState.showOVDConversionConfirmationPopUp}
        onConfirm={() => {
          setFormState((prevState) => ({
            ...prevState,
            showOVDConversionConfirmationPopUp: false,
            wclToOvdConversionFormIsActive: true,
            editMode: true,
          }));
        }}
        onCancel={() =>
          setFormState((prevState) => ({
            ...prevState,
            showOVDConversionConfirmationPopUp: false,
          }))
        }
      />
    </>
  );
};

export default LoanOfferDetails;
