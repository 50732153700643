import { useCallback } from "react";
import {
  LoanProduct,
  loanProductToTypeMap,
} from "../../../LoanContractsManagement/utils/types";
import { save_loan_offer_details_service } from "../API";
import { useLoadingAndErrorState } from "./useLoadingAndErrorState";
import {
  showMessage,
  SUCCESS_MESSAGES,
} from "../../../../../utils/notifications";
import { parseError } from "../../../../../utils/server";

export const useEditLoanOfferDetail = () => {
  const { loading, setLoading, setLoadingError, setPermissionError } =
    useLoadingAndErrorState();

  const editLoanOfferDetail = useCallback(
    async (loanProduct: string, loanReference: string, loanOffer: any) => {
      try {
        setLoading(true);
        setLoadingError(false);
        const loanProductType =
          loanProductToTypeMap[loanProduct as LoanProduct];
        const payload = {
          loanProduct: loanProductType,
          updateRequest: {
            [loanProductType]: {
              ...loanOffer,
              loanTenure: loanOffer?.tenure,
              loanProduct: loanProductType,
            },
          },
        };
        await save_loan_offer_details_service(
          loanProduct,
          loanReference,
          payload,
        );
        showMessage(
          SUCCESS_MESSAGES.EDIT_LOAN_OFFER.title,
          SUCCESS_MESSAGES.EDIT_LOAN_OFFER.message,
        );
      } catch (error: any) {
        if (error.response && error.response.status === 403) {
          setPermissionError(true);
          return;
        }
        showMessage("Loan Requests", parseError(error), "error");
        setLoadingError(true, parseError(error));
      } finally {
        setLoading(false);
      }
    },
    [],
  );

  return { editLoanOfferDetail, loading };
};
