import { useCallback } from "react";
import { wcl_to_ovd_conversion_service } from "../API";
import { IWCLToOVDConversionData } from "../../../LoanContractsManagement/utils/types";
import { useLoadingAndErrorState } from "./useLoadingAndErrorState";
import {
  showMessage,
  SUCCESS_MESSAGES,
} from "../../../../../utils/notifications";
import { parseError } from "../../../../../utils/server";

export const useConvertWCLToOVD = () => {
  const { loading, setLoading, setLoadingError, setPermissionError } =
    useLoadingAndErrorState();

  const convertWCLToOVD = useCallback(
    async (
      loanData: IWCLToOVDConversionData,
      onSuccess: () => void,
      onError?: () => void,
    ) => {
      try {
        setLoading(true);
        setLoadingError(false);
        await wcl_to_ovd_conversion_service(loanData);
        showMessage(
          SUCCESS_MESSAGES.CONVERT_WCL_TO_OVD.title,
          SUCCESS_MESSAGES.CONVERT_WCL_TO_OVD.message,
        );
        onSuccess();
      } catch (error: any) {
        if (error.response && error.response.status === 403) {
          setPermissionError(true);
          return;
        }
        showMessage("Workflow Requests", parseError(error), "error");
        setLoadingError(true, parseError(error));
        if (onError) onError();
      } finally {
        setLoading(false);
      }
    },
    [],
  );

  return { convertWCLToOVD, loading };
};
