export interface ILoanApprovalStatusDetails {
  currentApprovalStatus: string;
  previousApprovalStatus: string;
  previousApprovalStatusPassed: boolean;
  rejectedReason: string;
}
export interface IBusinessOwnerDetails {
  profileImage: string;
  firstName: string;
  lastName: string;
  middleName: string | null;
  phoneNumber: string;
  email: string;
}

export interface IResidentialAddress {
  addressLineOne: string;
  addressLineTwo: string;
  nearestLandmark: string;
  nearestBusstop: string;
  lga: string;
  city: string | null;
}

export interface IBusinessAddress {
  businessName: string;
  addressLineOne: string;
  addressLineTwo: string;
  nearestLandmark: string;
  nearestBusstop: string;
  lga: string;
  city: string | null;
}

export interface IManager {
  code: string;
  name: string;
}

export interface ILoanManagers {
  fcos: IManager[]; // Array of field credit officers
  pm: IManager;
  rm: IManager;
}

export interface ILoanRequestDetails {
  amountRequested: string;
  requestStatus: string;
  approvalStatusDetails: ILoanApprovalStatusDetails;
  businessOwnerDetails: IBusinessOwnerDetails;
  residentialAddress: IResidentialAddress;
  businessAddress: IBusinessAddress;
  mpManagers: {
    brms: IManager[];
    scs: IManager[];
    rms: IManager[];
  };
  loanManagers: ILoanManagers | null;
}
export interface IMoniepointManagers {
  mpManagers: {
    brm: IManager;
    sc: IManager;
    rm: IManager;
  }[];
}

export type UseQueryBusinessOwnerDetailsPayload = {
  loanReference: string | null;
};

export enum LoanOfferStatus {
  AWAITING_ELIGIBILITY_CHECK = "AWAITING_ELIGIBILITY_CHECK",
  ELIGIBILITY_EXPIRED = "ELIGIBILITY_EXPIRED",
  PENDING_KYC_CONFIRMATION = "PENDING_KYC_CONFIRMATION",
  FAILED_ELIGIBILITY_CHECK = "FAILED_ELIGIBILITY_CHECK",
  PASSED_ELIGIBILITY_CHECK = "PASSED_ELIGIBILITY_CHECK",
  DRAFT = "DRAFT",
  FILLING_LOAN_REQUEST = "FILLING_LOAN_REQUEST",
  AWAITING_LOAN_OFFER = "AWAITING_LOAN_OFFER",
  PERUSING_LOAN_OFFER = "PERUSING_LOAN_OFFER",
  NO_LOAN_OFFER = "NO_LOAN_OFFER",
  ACCEPTED = "ACCEPTED",
  OFFER_EXPIRED = "OFFER_EXPIRED",
  EXPIRED = "EXPIRED",
  TRIGGER_LOAN_REQUEST_APPROVAL = "TRIGGER_LOAN_REQUEST_APPROVAL",
  PENDING_LOAN_APPROVAL = "PENDING_LOAN_APPROVAL",
}

export interface IBusinessOwnerEnableLoanDetails {
  businessOwnerName: string;
  businessOwnerCode: string;
  loanAccess: "enabled" | "no_access";
}

export interface ILoanEligibilityResponse {
  businessOwnerName: string;
  businessOwnerCode: string;
  hasAccessToLoans: boolean;
  hasPendingLoanAccessRequest: boolean;
}

export interface ApprovalRequestStatus {
  lastApprovalRequestStatus: "APPROVED" | "REJECTED" | "PENDING" | null;
  comment: string | null;
}

export interface WCLConversionEligibility {
  offerConversionEligibility:
    | "CONVERTIBLE"
    | "OFFER_CONVERSION_PENDING"
    | "NOT_CONVERTIBLE";
  hasPriorRejectedRequest: boolean;
  rejectionReason: string;
}

export interface ILoanApprovalStatusDetails {
  type: string;
  status: string;
}

export interface ILoanRequestApprovalDetails {
  loanRequestStatus: "PENDING" | "ACCEPTED" | "REJECTED";
  loanAgreementAndFieldVerificationStatus: "PENDING" | "REJECTED" | "APPROVED";
  loanAmount: number;
  interestRate: number;
  loanTenure: number;
  loanTenureUnit: "Days" | "Months" | "Years";
  dateRequested: string;
  dateOfferedAccepted: string;
  managementFee: number;
  loanAgreements: ILoanApprovalStatusDetails[];
  fieldVerifications: ILoanApprovalStatusDetails[];
}
