import React from "react";
import JsonViewer from "react-json-view";
import AssignLGAsToFCOWorkFlow from "../../Viewers/AssignLGAsToFCO";
import UnAssignLGAsToFCOWorkFlow from "../../Viewers/UnAssignLGAsToFCO";
import PortfolioManagementSettingsUpdate from "../../Viewers/PortfolioManagementSettingsUpdate";
import ApproveDisapproveFCOPortfolioLevel from "../../Viewers/ApproveDisapproveFCOPortfolioLevel";
import AssignOrUnassignLGAsToPMWorkFlow from "../../Viewers/AssignOrUnassignLGAsToPM";
import FCOPortfolioLevelApproval from "../../Viewers/FCOPortfolioLevelApproval";
import ReAssignBusinessLoanRequestHandler from "../../Viewers/ReAssignBusinessLoanRequestHandler";
import MoniepointUsersOnboarding from "../../Viewers/MoniepointUsersOnboarding";
import AutoReassignLoanRequestsOrContracts from "../../Viewers/AutoReassignLoanRequestsOrContracts";
import ManuallyReassignLoanRequestsOrContracts from "../../Viewers/ManuallyReassignLoanRequestsOrContracts";
import PromoteDeactivateFCO from "../../Viewers/PromoteDeactivateFCO";
import ReassignLoanContractManager from "../../Viewers/ReassignLoancontractManagers";
import UpdatePortfolioLevelFCOWorkFlow from "../../Viewers/UpdatePortfolioLevelFco";
import LoanUpdateViewer from "../../Viewers/LoanUpdateViewer";
import UpdatePmMaxApprovableAmount from "../../Viewers/UpdatePmMaxApprovableAmount";
import TriggerBusinessVerification from "../../Viewers/TriggerLoanRequestBusinessVerification";
import EnableLoanViewer from "../../Viewers/EnableLoanViewer";
import OVDConversionLoanViewer from "../../Viewers/OVDConversionLoanViewer";
import SkipFieldVerificationViewer from "../../Viewers/SkipFieldVerificationViewer";
import DemoteOrDeactivatePm from "../../Viewers/DemoteOrDeactivatePM";
import CreateInterestWaiveApprovalViewer from "../../Viewers/CreateInterestWaiveApproval";

const viewers = {
  DEFAULT_VIEWER: {
    component: JsonViewer,
  },
  CREATE_FIELD_CREDIT_OFFICER: {
    component: MoniepointUsersOnboarding,
  },
  ASSIGN_LGAS_TO_FIELD_CREDIT_OFFICER: {
    component: AssignLGAsToFCOWorkFlow,
  },
  REMOVE_LGAS_FROM_FIELD_CREDIT_OFFICER: {
    component: UnAssignLGAsToFCOWorkFlow,
  },
  SYSTEM_SETTINGS_UPDATE: {
    component: PortfolioManagementSettingsUpdate,
  },
  APPROVED_FCO_PORTFOLIO_WORKFLOW: {
    component: ApproveDisapproveFCOPortfolioLevel,
  },
  ASSIGN_LGAS_TO_PORTFOLIO_MANAGER: {
    component: AssignOrUnassignLGAsToPMWorkFlow,
  },
  REMOVE_LGAS_FROM_PORTFOLIO_MANAGER: {
    component: AssignOrUnassignLGAsToPMWorkFlow,
  },
  CREATE_PORTFOLIO_MANAGER: {
    component: MoniepointUsersOnboarding,
  },
  PORTFOLIO_LEVEL_CREATION: {
    component: FCOPortfolioLevelApproval,
  },
  BUSINESS_LOAN_REQUEST_HANDLER_REASSIGNMENT: {
    component: ReAssignBusinessLoanRequestHandler,
  },
  REGIONAL_MANAGER_CREATION: {
    component: MoniepointUsersOnboarding,
  },
  AUTO_REASSIGN_LOAN_REQUESTS: {
    component: AutoReassignLoanRequestsOrContracts,
  },
  AUTO_REASSIGN_LOAN_CONTRACTS: {
    component: AutoReassignLoanRequestsOrContracts,
  },
  MULTI_BUSINESS_LOAN_REQUESTS_REASSIGNMENT: {
    component: ManuallyReassignLoanRequestsOrContracts,
  },
  MULTI_LOAN_CONTRACTS_REASSIGNMENT: {
    component: ManuallyReassignLoanRequestsOrContracts,
  },
  FIELD_CREDIT_OFFICER_DEACTIVATION: {
    component: PromoteDeactivateFCO,
  },
  FIELD_CREDIT_OFFICER_PROMOTION: {
    component: PromoteDeactivateFCO,
  },
  LOAN_CONTRACTS_REASSIGNMENT: {
    component: ReassignLoanContractManager,
  },
  FIELD_CREDIT_OFFICER_PORTFOLIO_LEVEL_UPDATE: {
    component: UpdatePortfolioLevelFCOWorkFlow,
  },
  LOAN_OFFER_UPDATE: {
    component: LoanUpdateViewer,
  },
  PORTFOLIO_MANAGER_UPDATE_MAX_APPROVABLE_AMOUNT: {
    component: UpdatePmMaxApprovableAmount,
  },
  TRIGGER_BUSINESS_VERIFICATION: {
    component: TriggerBusinessVerification,
  },
  GRANT_LOANS_ACCESS: {
    component: EnableLoanViewer,
  },
  CONVERT_WCL_LOAN_OFFER_TO_OVD: {
    component: OVDConversionLoanViewer,
  },
  SKIP_FIELD_VERIFICATION: {
    component: SkipFieldVerificationViewer,
  },
  PORTFOLIO_MANAGER_DEMOTION: {
    component: DemoteOrDeactivatePm
  },
  PORTFOLIO_MANAGER_DEACTIVATION: {
    component: DemoteOrDeactivatePm
  },
  CREATE_INTEREST_WAIVE_PERIOD: {
    component: CreateInterestWaiveApprovalViewer,
  },
};

type ViewerType = keyof typeof viewers;

export const getViewer = (data: any): React.JSXElementConstructor<any> => {
  const type = data?.workflowRequestType;
  const viewer = viewers[type as ViewerType] || viewers["DEFAULT_VIEWER"];
  return viewer?.component;
};
