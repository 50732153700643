import React from "react";
import {
  create_interest_waiver,
} from "../API";
import { showMessage, showServerError } from "../../../../utils/notifications";

interface Params {
  payload: {
    contractReference: string;
    startDate: string;
    endDate: string;
    comment: string;
  };
  callback : () => void;
}

const useCreateInterestWaiver = () => {
  const [isLoading, setIsLoading] = React.useState(false);

  const createInterestWaiver = async ({ payload, callback }: Params) => {
    try {
      setIsLoading(true);

      const response = await create_interest_waiver(payload);

      if (response) {
        callback();
        showMessage("SuccessFul", "Request has been sent for approval.");
      }
    } catch (error) {
      showServerError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    createInterestWaiver,
  };
};

export default useCreateInterestWaiver;
